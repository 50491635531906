.announcementsWrapper {
  width: 100vw;
  min-height: 110vh;
  display: flex;
  justify-content: center;
}

.announcementsWrapper ul {
  display: flex;
  flex-wrap: wrap;
  width: 88%;
  height: 50%;
  max-height: 50%;
  overflow: hidden;
  background-color: transparent !important;
}

.announcementsWrapper ul li {
  flex: 0 1 calc(33.33% - 20px);
  margin: 1rem;
  box-sizing: border-box;
  display: flex;
  width: 18%;
  height: 45vw;
  align-items: center;
  flex-direction: column;
  border: 0.2rem solid #ccc; /* Light gray border */
  border-radius: 1rem;
  background-color: transparent !important;
  overflow: hidden; /* Hide overflow content */
  transition: transform 0.3s ease-in-out;
  position: relative; /* Make the parent li a positioning context for the absolute positioning of the button */
}

.announcementsWrapper ul li:hover {
  transform: scale(1.01); /* Increase size on hover */
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.262);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.262);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.262);
}

.imageContainer {
  width: 95%;
  height: 25%;
  background-color: #333; /* Dark gray background */
  overflow: hidden;
  position: absolute;
  top: 0;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Maintain aspect ratio while covering the container */
}

.titleContainer {
  position: absolute;
  top: 30%;
  max-width: 95%;
  background-color: transparent !important;
}
.horizontalLineOne {
  position: absolute;
  top: 25%;

  width: 100%;
  border-top: 1px solid #ccc; /* Adjust color as needed */
}
.horizontalLineTwo {
  position: absolute;
  top: 38%;

  width: 100%;
  border-top: 1px solid #ccc; /* Adjust color as needed */
}

.announcementsWrapper h2 {
  text-align: center;
  color: black;
  font-size: 2.2rem;
  word-wrap: break-word !important;
  background-color: transparent !important;
}

.priceContainer {
  position: absolute;
  top: 40%;

  max-width: 95%;
  height: 20%;
  background-color: transparent;
  display: flex;
}

.priceContainer p {
  background-color: transparent !important;
}

.announcementsWrapper ul li p {
  text-align: center;
  overflow: hidden;
  white-space: normal;
  height: 80%;
  color: #333; /* Dark gray text */
  background-color: yellow;
  font-size: 1.5rem;
  word-wrap: break-word !important;
}

@media screen and (max-width: 699px) and (min-width: 451px) {
  .announcementsWrapper ul li {
    flex: 0 1 calc(100% - 20px); /* Full width for li on smaller screens */
    margin: 1rem 0; /* Adjust margin for better spacing */
  }

  .announcementsWrapper h2 {
    font-size: 1.6rem; /* Adjust font size for h2 on smaller screens */
  }

  .priceContainer {
    font-size: 0.5rem; /* Adjust font size for priceContainer on smaller screens */
  }
}
@media screen and (max-width: 850px) and (min-width: 700px) {
  .announcementsWrapper ul li {
    flex: 0 1 calc(50% - 20px); /* Full width for li on smaller screens */
    margin: 1rem 0; /* Adjust margin for better spacing */
    height: 60vh;
  }

  .announcementsWrapper h2 {
    font-size: 1.6rem; /* Adjust font size for h2 on smaller screens */
  }

  .imageContainer {
  }

  .priceContainer {
    font-size: 0.5rem; /* Adjust font size for priceContainer on smaller screens */
  }
}

@media screen and (max-width: 450px) {
  .announcementsWrapper ul li {
    flex: 0 1 calc(100% - 20px); /* Full width for li on smaller screens */
    margin: 1rem 0; /* Adjust margin for better spacing */
    height: 80vh;
  }

  .announcementsWrapper h2 {
    font-size: 1.2rem; /* Adjust font size for h2 on smaller screens */
  }

  .priceContainer {
    font-size: 0.2rem; /* Adjust font size for priceContainer on smaller screens */
  }
  .titleContainer {
    position: absolute;
    top: 30%;
    max-width: 95%;
    background-color: transparent; /* Light gray background */
  }
}
