* {
  margin: 0;
  padding: 0;
  background-color: rgb(255, 255, 255);
}

html {
  font-size: 10px;
  box-sizing: border-box;
  height: 100%;
}

a {
  text-decoration: none !important;
}

li {
  list-style-type: none;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
