.productsWrapper {
  width: 100vw;
  min-height: 110vh;
  display: flex;
  justify-content: center;
}

.productsWrapper ul {
  display: flex;
  flex-wrap: wrap;
  width: 88%;
  height: 50%;
  max-height: 50%;
  overflow: hidden;
  background-color: transparent !important;
}

.productsWrapper ul li {
  flex: 0 1 calc(33.33% - 20px);
  margin: 1rem;
  box-sizing: border-box;
  display: flex;
  width: 18%;
  height: 20vw;
  align-items: center;
  flex-direction: column;
  border: 0.2rem solid #ccc; /* Light gray border */
  border-radius: 1rem;
  background-color: transparent !important;
  overflow: hidden; /* Hide overflow content */
  transition: transform 0.3s ease-in-out;
  position: relative; /* Make the parent li a positioning context for the absolute positioning of the button */
}

.productsWrapper ul li:hover {
  transform: scale(1.01); /* Increase size on hover */
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.262);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.262);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.262);
}

.Link {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  color: white;
  margin-top: 0 auto;
  background-color: transparent !important;
}

.imageContainer {
  width: 100%;
  height: 70%;
  background-color: #333; /* Dark gray background */
  overflow: hidden;
  position: absolute;
  top: 0;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio while covering the container */
}

.titleProductContainer {
  margin-top: 50%;
  max-width: 95%;
  background-color: transparent; /* Light gray background */
}

.productsWrapper h2 {
  text-align: center;
  color: black;
  font-size: 2.2rem;
  word-wrap: break-word !important;
}

.priceProductContainer {
  max-width: 95%;
  height: 20%;
  background-color: transparent;
  display: flex;
}

.priceProductContainer p {
  background-color: transparent !important;
}

.productsWrapper ul li p {
  text-align: center;
  overflow: hidden;
  white-space: normal;
  height: 80%;
  color: #333; /* Dark gray text */
  background-color: yellow;
  font-size: 1.5rem;
  word-wrap: break-word !important;
}

@media screen and (max-width: 500px) {
  .productsWrapper ul li {
    flex: 0 1 calc(100% - 20px); /* Full width for li on smaller screens */
    margin: 1rem 0; /* Adjust margin for better spacing */
    height: 65vw;
  }

  .imageContainer {
    height: 60%;
  }
  .productsWrapper h2 {
    margin-top: -1vh;
  }
}

@media screen and (min-width: 501px) and (max-width: 700px) {
  .productsWrapper ul li {
    flex: 0 1 calc(100% - 20px); /* Full width for li on smaller screens */
    margin: 1rem 0; /* Adjust margin for better spacing */
    height: 40vw;
  }
  .productsWrapper h2 {
    font-size: 1.6rem; /* Adjust font size for h2 on smaller screens */
    margin-top: -20vh;
  }

  .imageContainer {
    height: 50%;
  }

  .priceProductContainer {
    font-size: 0.5rem; /* Adjust font size for priceContainer on smaller screens */
    margin-top: -14vh;
  }
}
@media screen and (max-width: 850px) and (min-width: 700px) {
  .productsWrapper ul li {
    flex: 0 1 calc(50% - 20px); /* Full width for li on smaller screens */
    margin: 1rem 0; /* Adjust margin for better spacing */
  }

  .productsWrapper h2 {
    font-size: 1.6rem; /* Adjust font size for h2 on smaller screens */
    margin-top: -14vh;
  }

  .imageContainer {
    height: 50%;
  }

  .priceProductContainer {
    font-size: 0.5rem; /* Adjust font size for priceContainer on smaller screens */
    margin-top: -11vh;
  }
}

/**/
