.aboutWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  margin-bottom: 2%;
  gap: 4vw;
}

.imgContainer {
  min-width: 40vw;
  margin-left: 8vw;
}

.imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5%;
}

.textWrapper {
  min-width: 40vw;
  background-color: rgb(0, 0, 0);
  width: 40vw;
  margin-right: 8vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 5%;
}

.textContainer {
  width: 100%;
  overflow-wrap: break-word;
}

.titleContainer h1 {
  color: #efc714 !important;
  font-size: 5rem;
  background-color: rgb(0, 0, 0);
  text-align: center;
}

.textContainer p {
  font-size: 1.4rem;
  text-align: justify;
  background-color: rgb(0, 0, 0);
}

.linksContainer ul {
  display: flex;
  margin-top: 2%;
  flex-direction: row;
  justify-content: center;
  font-size: 2rem;
  background-color: transparent;
  text-align: center;
  border-radius: 5%;
}

.textContainer {
}

.titleContainer,
.textContainer,
.linksContainer,
.linksContainer li,
.linksContainer a {
  background-color: transparent;
  border-radius: 5%;
}

@media screen and (max-width: 650px) {
  .imgContainer {
    min-width: 20rem;
  }
  .titleContainer h1 {
    color: #efc714 !important;
    font-size: 2rem;
  }
  .textContainer p {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 651px) and (max-width: 800px) {
  .imgContainer {
    min-width: 30rem;
  }
  .titleContainer h1 {
    color: #efc714 !important;
    font-size: 3rem;
  }
  .textContainer p {
    font-size: 1rem;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  .imgContainer {
    min-width: 40rem;
  }
  .titleContainer h1 {
    font-size: 4rem;
  }
  .textContainer p {
    font-size: 1.2rem;
  }
}
