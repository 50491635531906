.productDetailWrapper {
  width: 92vw;
  min-height: 97.6vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 5rem 5rem 0 0;
  margin-left: 1.8vw;
  background-color: rgb(255, 255, 255);
  margin-top: 2%;
}

.prices {
  display: flex;
  flex-direction: row;
  background-color: rgb(255, 255, 255);
}
p {
  background-color: rgb(255, 255, 255);
}
.imagesContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-height: 90%;
  min-height: 90%;

  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 5rem 0 0 0;
}

.bigImageWrapper {
  width: 80%;
  max-height: 60%;
  min-height: 60%;

  border-radius: 5%;
  display: flex;
  background-color: rgb(255, 255, 255);
}

.bigImageWrapper img {
  width: 100%;
  height: 100%;
  border-radius: 2%;

  background-color: transparent;
  object-fit: fill;
}

.smallImagesWrapper {
  display: flex;
  width: 75%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5%;
  background-color: rgb(255, 255, 255);
}
.smallImagesWrapper li {
  height: 65%;
  cursor: pointer;
  width: 15%;
  background-color: rgb(255, 255, 255);
}

.smallImage {
  opacity: 1;
  transition: opacity 0.15s ease-in;
}

.smallImage:hover {
  opacity: 0.7;
}

.smallImagesWrapper img {
  width: 90%;
  height: 100%;
  background-color: transparent;
  object-fit: fill;
}

.announcementsWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  min-height: 90%;
  height: fit-content;
  padding: 2rem;
  border-radius: 2%;
  max-width: 20%;
}

.announcementsWrapper h1 {
  color: rgb(21, 21, 21);
  font-size: 5rem;
  background-color: rgb(255, 255, 255);
}
.announcementsWrapper span {
  font-size: 2rem;
  margin-bottom: 1rem;
  background-color: rgb(255, 255, 255);
}

.announcementsWrapper p {
  text-align: justify;
  font-size: 1.5rem;
}

@media screen and (max-width: 1000px) {
  .productDetailWrapper {
    flex-direction: column;
  }
}

.otherProducts {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin-right: 6%;
  background-color: transparent !important;
}

.announcementsWrapper ul li {
  flex: 0 1 calc(100% - 20px);
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100vw;
  align-items: center;
  flex-direction: column;
  border: 0.2rem solid #ccc; /* Light gray border */
  border-radius: 1rem;
  background-color: transparent !important;
  overflow: hidden; /* Hide overflow content */
  transition: transform 0.3s ease-in-out;
  position: relative; /* Make the parent li a positioning context for the absolute positioning of the button */
}

.announcementsWrapper ul li:hover {
  transform: scale(1.01); /* Increase size on hover */
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.262);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.262);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.262);
}

.announcementsWrapper ul li img {
  max-width: 100%;
  height: 100%;
  object-fit: fill; /* Maintain aspect ratio while covering the container */
}

.announcementsWrapper h2 {
  text-align: center;
  color: black;
  font-size: 2.2rem;
  word-wrap: break-word !important;
}

.priceContainer p {
  background-color: transparent !important;
}

.announcementsWrapper ul li p {
  height: 80%;
  color: #333; /* Dark gray text */
  background-color: yellow;
  font-size: 1.5rem;
  overflow: hidden;
  white-space: normal;
  text-align: center;

  word-wrap: break-word !important;
}

.description {
  width: 75vw;
  max-width: 92vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.8vw;
  margin-top: 5%;
  background-color: rgb(255, 255, 255);
  border-radius: 5rem;
}

.description p {
  overflow: hidden;
  white-space: normal;
  text-align: justify;

  word-wrap: break-word !important;
}

.neredenAlabilirim {
  background-color: rgb(220, 52, 68);
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 2%;
  border-radius: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.customImageContainerStyles {
  height: 50%;
}

.customTitleContainerStyles h2 {
  font-size: 1.5rem;
}

.editButton {
  background-color: rgb(32, 50, 103);
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 2%;
  border-radius: 5%;
  width: 50%;
  display: flex;
  justify-content: center;
  font-size: 0.5rem;
}

/* 751Den küçük */
.otherProductsW2 {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center; /* Adjust the space between items */
  flex-direction: row;
}

.bottomWrapper ul li {
  flex: 0 1 calc(50% - 20px);
  box-sizing: border-box;
  margin: 10px; /* Add some margin between the items */
  border: 0.2rem solid #ccc;
  border-radius: 1rem;
  background-color: transparent !important;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  position: relative;
}

.announcementsWrapperW2 ul li:hover {
  transform: scale(1.01); /* Increase size on hover */
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.262);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.262);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.262);
}
.announcementsWrapperW2 ul li img {
  max-width: 100%;
  height: 100%;
  object-fit: fill; /* Maintain aspect ratio while covering the container */
}

.titleContainer {
  margin-top: 50%;
  max-width: 95%;
  height: 10%;
  background-color: transparent;
}

.announcementsWrapper h1 {
  text-align: center;
  color: black;
  font-size: 2.2rem;
  word-wrap: break-word !important;
  background-color: transparent;
}

.priceContainer p {
  background-color: transparent !important;
}

.announcementsWrapperW2 ul li p {
  height: 80%;
  color: #333; /* Dark gray text */
  background-color: yellow;
  font-size: 1.5rem;
  overflow: hidden;
  white-space: normal;
  text-align: center;

  word-wrap: break-word !important;
}

.topWrapper {
  display: flex;
  flex-direction: row;
}
.customImageContainerStyles2 {
  height: 60% !important;
}

.otherProductsW2 .titleContainer {
  margin-top: 50%;
}

.otherProductsw2 li a h2 {
  margin-top: 14vh;
}
@media screen and (max-width: 500px) {
  .productsWrapper ul li {
    flex: 0 1 calc(100% - 20px); /* Full width for li on smaller screens */
    margin: 1rem 0; /* Adjust margin for better spacing */
    height: 65vw;
  }

  .imageContainer {
    height: 60%;
  }
  .productsWrapper h2 {
    margin-top: -1vh;
  }
}
.descriptionWiki {
  width: 75vw;
  max-width: 92vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 6.8vw;
  margin-top: 5%;
  background-color: rgb(255, 255, 255);
  border-radius: 5rem;
}

.descriptionWiki p {
  overflow: hidden;
  white-space: normal;
  text-align: justify;

  word-wrap: break-word !important;
}

.neredenAlabilirimWiki {
  background-color: rgb(220, 52, 68);
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 2%;
  border-radius: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 851px) and (min-width: 450px) {
  .neredenAlabilirimWiki {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 450px) {
  .neredenAlabilirimWiki {
    width: 200%;

    font-size: 0.6rem;
  }
}
