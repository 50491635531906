.footerWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 2%;
  height: fit-content;
  width: 100%;
  z-index: 999;
  padding-top: 1%;
  padding-bottom: 0.5%;
  background-color: rgb(21, 21, 21);
}

.textContainer {
  width: 95%;
  margin-left: 2%;
  text-align: center;
  background-color: rgb(21, 21, 21);
}

.textContainer p {
  color: aliceblue;
  background-color: rgb(21, 21, 21);

  font-size: 0.7rem;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: fixed;
  top: 50%;
  right: 0%;
  align-items: center;
}

.icon {
  font-size: 2rem;
  margin-right: 2rem;
  background-color: transparent;
  margin-bottom: 2rem;
}

.footer ul li a {
  background: none;
  background-color: transparent;

  transition: opacity 0.1s ease-in;
  color: #1c1c1c;
}

.instagram,
.facebook,
.youtube,
.whatsapp {
  color: black;
  transition: all 0.15s ease-in-out;
  background-color: transparent;
}

.footer ul li a:hover {
  opacity: 1;
}
.instagram:hover {
  color: #ff418a !important;
}

.facebook:hover {
  color: #0765ff !important;
}
.youtube:hover {
  color: #ff0000 !important;
}

.whatsapp:hover {
  color: #41cba5 !important;
}
