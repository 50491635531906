.contactPageWrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
}

.contactContainer {
  background-color: rgb(21, 21, 21) !important;
  color: aliceblue;
  width: 65%;
  height: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12%;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
}

.contactUsHeader {
  color: #efc714 !important;
  font-size: 5rem;
  background-color: transparent;
  margin-top: 3%;
}
.contactContainer ul,
.contactContainer ul li,
.contactContainer ul li h2,
.contactContainer ul li address,
.contactContainer ul li p,
.contactContainer ul li h3,
.contactContainer ul li a {
  background-color: rgb(21, 21, 21) !important;
}

.contactUsHeader h2 {
  text-align: start;
}

.contactContainer ul {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
}

.contactContainer ul li {
  width: 33.3%;
}

.contactContainer ul li address,
.contactContainer ul li p {
  width: 80%;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  text-align: center;
}

.showForm {
  font-size: 1.5rem;
  color: rgb(90, 90, 248);
  text-align: center;
}

.showForm span {
  background-color: rgb(21, 21, 21) !important;
  color: rgb(1, 92, 75);
}

.contactContainer ul li,
.contactContainer ul li {
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mapContainer {
  position: absolute;
  margin-top: 4%;
  width: 24rem;
  height: 18rem;
  clip: rect(6rem, auto, auto, auto);
  border-radius: 0.2rem;
}

@media screen and (min-width: 725px) and (max-width: 1000px) {
  .contactContainer {
    width: 85%;
  }
}

@media screen and (min-width: 525px) and (max-width: 724px) {
  .contactContainer {
    width: 85%;
  }
  .contactUsHeader {
    font-size: 3rem;
    text-align: justify;
  }
  .showForm {
    font-size: 1rem;
  }
  .contactContainer ul li h2 {
    font-size: 1rem;
  }
  .contactContainer ul li address,
  .contactContainer ul li p {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 524px) {
  .contactContainer {
    width: 100%;
  }

  .contactUsHeader {
    font-size: 3rem;
    text-align: justify;
  }
  .showForm {
    font-size: 1rem;
  }
  .contactContainer ul li h2 {
    font-size: 1rem;
  }
  .contactContainer ul li address,
  .contactContainer ul li p {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 400px) {
  .contactContainer {
    width: 100%;
  }

  .contactUsHeader {
    font-size: 2.5rem;
    text-align: justify;
  }

  .showForm {
    font-size: 1rem;
  }
  .contactContainer ul li h2 {
    font-size: 1rem;
  }
  .contactContainer ul li address,
  .contactContainer ul li p {
    font-size: 0.7rem;
  }
}
