.videosWrapper {
  width: 100%;
  height: fit-content;
  min-width: 100vw;
}

.videosWrapper ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.videosWrapper li {
  flex: 0 1 calc(33.33% - 20px);
  box-sizing: border-box;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 0.2rem solid white;
  background-color: rgb(21, 21, 21);
  border-radius: 1rem;
  padding: 1rem;
}

.video {
  border-radius: 1rem;
  width: 35vw;
  height: 250px;
  background-color: rgb(21, 21, 21);
}

.videosWrapper h2 {
  text-align: center;
  color: aliceblue;
  font-size: 1.5rem;
  padding: 0.5rem;
  background-color: rgb(21, 21, 21);
}

@media screen and (max-width: 650px) {
  /* Add styles for screens less than or equal to 650px if needed */
}

@media screen and (min-width: 651px) and (max-width: 800px) {
  /* Add styles for screens between 651px and 800px if needed */
}

@media screen and (max-width: 1000px) {
  .videosWrapper li {
    flex: 0 1 calc(90% - 20px);
  }
  .video {
    border-radius: 1rem;
    width: 100%; /* Adjusted width for responsiveness */
    height: 375px;
  }
}
