.navContainer {
  display: flex;
  height: 5%;
  height: 4.7rem;
  min-height: 3.5rem;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 98.5vw;
  background-color: rgb(21, 21, 21);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
  border-bottom: 0.1rem solid white;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.name {
  min-width: 5vw;
  max-width: 8vw;
  width: 5vw;
  justify-self: flex-start;
  align-self: center;
  margin-left: 3%;
  background-color: transparent;
}
.name a {
  background-color: transparent;
}

.name img {
  max-width: 100%;
  height: auto;
  background-color: transparent;
}
.nameSmall {
  min-width: 5vw;
  max-width: 8vw;
  width: 5vw;
  justify-self: flex-start;
  align-self: center;
  margin-left: 3%;
  background-color: transparent;
  position: absolute;
  top: 1%;
  left: 2%;
}
.nameSmall a {
  background-color: transparent;
}

.nameSmall img {
  max-width: 100%;
  height: auto;
  background-color: transparent;
}
.navLinksContainer li a,
.navLinksContainer li button {
  color: aliceblue;
  font-size: 1.5rem;
  background-color: transparent;
}

.active {
  border-bottom: 0.1rem solid white !important;
}
.navbarListLogoff,
.navbarListShare,
.navbarListMain,
.navbarListProduct,
.navbarListTest,
.navbarListNews,
.navbarListAbout,
.navbarListButton,
.navbarListAddress {
  border: 0.2rem solid transparent;
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
  width: 13rem;
  display: flex;
  justify-content: center;
  position: absolute;
  background-color: transparent;
  top: 1.5%;
}

.navbarListMain:hover,
.navbarListProduct:hover,
.navbarListTest:hover,
.navbarListNews:hover,
.navbarListAbout:hover,
.navbarListButton:hover,
.navbarListAddress:hover {
  opacity: 0.5;
  border: 0.2rem solid white;
  color: white;
}
.navbarListShare {
  left: 13%;
}
.navbarListMain {
  left: 22%;
}
.navbarListProduct {
  left: 33%;
}

.navbarListTest {
  left: 44%;
}
.navbarListNews {
  left: 55%;
}

.navbarListAbout {
  left: 66%;
}
.navbarListButton {
  left: 77%;
}

.navbarListAddress {
  left: 88%;
}

.navbarListLogoff {
  left: 6%;
}
/*
@media screen and (max-width: 650px) {
  .navLinksContainer li a,
  .navLinksContainer li button {
    font-size: 1rem;
  }
  .navContainer {
    height: 3.5rem;
  }
}

@media screen and (min-width: 651px) and (max-width: 800px) {
  .navLinksContainer li a,
  .navLinksContainer li button {
    font-size: 1.2rem;
  }
  .navContainer {
    height: 4rem;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  .navLinksContainer li a,
  .navLinksContainer li button {
    font-size: 1.5rem;
  }
  .navContainer {
    height: 4.5rem;
  }
}*/

/* LOWWWW*/

.lowNavContainer {
  display: flex;
  min-height: 5vh;
  height: fit-content;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 98.5vw;
  background-color: rgb(21, 21, 21);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
  border-bottom: 0.1rem solid white;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
.lowWidthNavLinksContainer {
  display: flex;
  width: 100%;
  min-height: 3.5rem;
  background-color: transparent !important;

  flex-direction: column;
  align-items: center;
  width: 98.5vw;
  background-color: rgb(21, 21, 21);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
}

.lowWidthNavLinksContainer li,
.lowWidthNavLinksContainer a,
.lowWidthNavLinksContainer button {
  background-color: transparent !important;
  color: white;
  font-size: 2rem;
}
