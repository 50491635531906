.addressWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
  min-height: fit-content;
  height: 75vh;
}

.addressContainer {
  color: rgb(21, 21, 21);
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}

.addressContainer h1 {
  margin-bottom: 2%;
  background-color: transparent;
}

.addressContainer p {
  background-color: transparent;
}

.mapContainer {
  position: absolute;
  width: 96rem;
  height: 45rem;
  margin-top: 3%;
  clip: rect(6rem, auto, auto, auto);
}

.mapContainer iframe {
  width: 100%;
  height: 100%;
  border-radius: 15rem;
}

@media screen and (max-width: 650px) {
  .addressContainer {
    font-size: 1.5rem;
  }

  .mapContainer {
    width: 48rem;
    height: 36rem;
  }
}

@media screen and (max-width: 1250px) {
  .addressContainer {
    font-size: 1.8rem;
  }

  .mapContainer {
    width: 48rem;
    height: 36rem;
  }
}
