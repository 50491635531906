.rootLayoutContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 100vw;
}

.outlet {
  min-height: 88vh;
  max-width: 90vw;
}
