.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent black background */
  z-index: 100; /* Ensure the backdrop is on top of other elements */
}

.contactWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-width: 0 0.1rem 0.1rem 0.1rem;
  border-style: none solid solid solid;
  border-color: white;
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: rgb(21, 21, 21);
  width: 11.5rem;
  height: fit-content;
  align-items: center;
  z-index: 9999;
  top: 6.6%;
  left: 77.7%;
}

.contactWrapper a {
  font-size: 1rem !important;
  color: aliceblue !important;
  padding: 3.2%;
  position: relative;
  z-index: 1;
  background-color: transparent;
}

.contactWrapper a:not(span):hover {
  opacity: 0.9;
}

@media screen and (max-width: 451px) {
  .contactWrapper {
    left: 35%;
    top: 34.5%;
  }
}

@media screen and (min-width: 451px) and (max-width: 650px) {
  .contactWrapper {
    left: 40%;
    top: 34.5%;
  }
}

@media screen and (min-width: 651px) and (max-width: 800px) {
  .contactWrapper {
    left: 43%;
    top: 34.5%;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  .contactWrapper {
    left: 44%;
    top: 34.5%;
  }
}

@media screen and (min-width: 1300px) {
  .contactWrapper {
    left: 77.5%;
    top: 6.5%;
  }
}
