.shareWrapper {
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shareWrapper h2 {
  font-size: 5rem;
  color: rgb(21, 21, 21);
  margin-bottom: 2rem;
}

.custom-button {
  width: 25vw;
  height: 15vh;
  font-size: 3rem;
  margin-bottom: 2rem;
  background-color: rgb(21, 21, 21);
  display: flex;
  align-items: center;
  justify-content: center;
}
