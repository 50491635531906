.wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  min-height: 80vh;
}

.container {
  width: 80%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  text-align: justify;
  background-color: rgb(21, 21, 21);
  border-radius: 2rem;
  margin-top: 2%;
}

.container h1 {
  margin-top: 1.5rem;
  font-size: 3.5rem;
  color: yellow;
  text-align: center;
  background-color: rgb(21, 21, 21);
}

.container p {
  color: aliceblue;
  width: 80%;
  font-size: 1.5rem;
  background-color: rgb(21, 21, 21);
}

@media screen and (max-width: 650px) {
  .container p {
    font-size: 0.8rem;
  }
  .container h1 {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 651px) and (max-width: 800px) {
  .container p {
    font-size: 1rem;
  }
  .container h1 {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  .container p {
    font-size: 1.2rem;
  }
  .container h1 {
    font-size: 3rem;
  }
}
