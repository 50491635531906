.errorClass {
  border: 0.2rem solid brown !important;
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 0.35rem;
}

.input {
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 0.35rem;
}

.invLinkMes {
  color: brown;
  margin-top: -1rem;
}

.validMes {
  color: green;
  margin-top: -1rem;
}

.loading {
  color: blue;
  margin-top: -1rem;
}
